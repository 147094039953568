const requiredField = (field) => (v) => !!v || `${field} es requerido`;
const onlyNumbers = (field) => (v) => {
  if (v) {
    return /^[0-9]+$/.test(v) || `${field} solo debe contener números`;
  }
  return true;
};
const phoneNumber = (v) => {
  const error = 'Formato incorrecto, debe ser numérico y de 9 dígitos'
  if (v === 0) {
    return error;
  } else if (!v) {
    return true;
  } else {
    return /^[0-9]{9}$/.test(v) || error;
  }
};
const isEmail = (v) => {
  if (v) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email no es válido';
  }
  return true;
};

export default function (Vue) {
  Vue.mixin({
    methods: {
      $chkRequiredField: requiredField,
      $chkOnlyNumbers: onlyNumbers,
      $chkPhoneNumber: phoneNumber,
      $chkIsEmail: isEmail,
    }
  });
}
