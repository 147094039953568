import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#00A8E1',
        secondary: '#003764',
        error: '#EA1D76',
        warning: '#FF8300',
        info: '#C4D600',
        success: '#00B09A',
        nota: '#BBDEFB'
      }
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  }
});
