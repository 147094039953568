import Vue from 'vue'
import VueRouter from 'vue-router';
Vue.use(VueRouter)

/* dynamic pages */
const Formulario = () => import('./pages/Formulario.vue' /* webpackChunkName: 'Formulario' */);
const routes = [
  {
    path: '/',
    name: 'formulario',
    component: Formulario,
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;

