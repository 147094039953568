<template>
  <v-app class="accesibilidad">
    <v-row class="d-flex justify-space-around ">
      <v-col cols="12" sm="8" md="6" class=" d-flex justify-space-around ">
        <v-row>
          <v-col class="d-flex justify-center">
            <img src="https://archivos.demre.cl/public/logo_demre_200.png" class="py-6">
          </v-col>
          <v-col class="d-flex justify-center">
            <img src="https://archivos.demre.cl/public/logo_subsecretaria_ed_200.png" class="py-6">
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-main class="contenedor">
      <router-view />
    </v-main>
    <d-notifier />
    <d-footer />
    <d-video-psd />
  </v-app>
</template>

<script>
import Footer from '@demre/client-common/src/components/footer';
import Notifier from '@demre/client-common/src/components/notifier';
import VideoPsd from './common/Video';

export default {
  components: {
    'd-footer': Footer,
    'd-notifier': Notifier,
    'd-video-psd': VideoPsd,
  },
  data: function () {
    return {};
  },
  computed: {
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
    items() {
      return [
        { icon: 'info', text: 'Home', href: { name: 'home' }, active: false },
      ]
    }
  },

};
</script>

<style scope>
.contenedor{
  margin-bottom: 55px;
}
.logos {
  /* max-height: 150px; */
  margin-top: 5%;
}

.v-application.no-accesibilidad .only-pesd {
  display: none;
}

</style>
