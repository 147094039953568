'strict';
function urlVideos() {
  return {
    F1: 'sEn8zXqTwsI', // titulo
    F2: 'gQgUCrB6wLQ',
    F3: 'qTczlfkDYe0',
    F4: 'N4LHGj7x_zU',
    F5: 'zP2-g0Fk0rE',
    F6: 'DxNcmqxW6Bc',
    F7: 'hfBalDJY4SY',
    E1: 'GedqkO625U4',
    E2: 'BmnVHEsXdVM'


  };
}

export default {
  urlVideos
};
