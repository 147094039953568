import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import archivos from '@demre/client-common/src/plugins/archivos';
import router from './router'
import validator from './utils/validator';
import store from "./store";

import VueTheMask from 'vue-the-mask'

Vue.use(VueTheMask)
Vue.use(archivos);
Vue.use(validator);
Vue.config.productionTip = false;

async function init() {
  const { vigente, titulo, faseActiva, proceso, tiposCausales, tiposUsuarios } = await fetch(process.env.VUE_APP_SERVER_HOST + '/configuracion', {
    method: 'GET'
  })
  .then((response) => {
    if(!response.ok) {
      throw new Error('Problema con formulario de apelación. Intente mas tarde.')
    }
    return response.json();
  });

  if(!vigente) {
    throw new Error(`El aplicativo de apelación a la inscripción a la PAES ${faseActiva == 6 ? 'de Invierno' : 'Regular'} ${proceso - 1 } se extiende entre las 09:00 y 17:00 horas del día jueves 01 de agosto de 2024. Actualmente no se encuentra disponible.`);
  }

  store.commit('setTitulo', titulo);
  store.commit('setTiposCausales', tiposCausales);
  store.commit('setTiposUsuarios', tiposUsuarios);
}
init()
.then(async () => {
  new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
  }).$mount('#app');
})
.catch(async (err) => {
  const mensaje = err.message;
  const { default: Impedimento } = await import( "./Impedimento.vue" /* webpackChunkName: 'impedimento' */);
  new Vue({
  vuetify,
  render: (h) => h(Impedimento, { props: { mensaje } }),
  }).$mount("#app");
});
